/* ==========================================================================
   HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
   ========================================================================== */

html,
button,
input,
select,
textarea {
  color: #4c4d4f;
}

body {
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ==========================================================================
  
  Table of Contents

  1. Base
  2. Layout
  3. Module

*/

/* 1. Base
   ========================================================================== */

body {
  margin: 0 auto;
  width: 960px;
}

p {
  font-size: 0.8em;
}

p.lead {
  margin-top: 0;
  font-size: 1.4em;
  color: #006bb7;
}

/* 2. Layout
   ========================================================================== */

/* Header */
.masthead {
  margin: 0 0 18px 0;
  position: relative;
  z-index: 5;
}

.masthead .contact-details {
  margin: 10px;
  text-align: right;
  font-size: 1.8em;
  color: #006bb7;
}

.masthead .contact-details span {
  color: #4c4d4f;
}

.masthead .logo {
  float: left;
}

.primary-navigation {
  margin-top: 11px;
  float: right;
  position: relative;
  z-index: 1;
}

.primary-navigation ul {
  margin: 0;
  padding: 0;
}

.primary-navigation li {
  margin-left: 8px;
  float: left;
  position: relative;
}

.primary-navigation li:first-child {
  margin-left: 0;
}

.primary-navigation li {
  height: 32px;
}

.primary-navigation a {
  display: block;
  padding: 5px 0;
  width: 150px;
  border-radius: 8px;
  font-size: 0.8em;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: #006bb7;
}

.primary-navigation .sub-menu {
  display: none;
  background: #4c4d4f;
  border: 1px solid #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 32px;
  left: 0;
  z-index: 5000;
}

.primary-navigation .sub-menu:after,
.primary-navigation .sub-menu:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  z-index: 5000;
  pointer-events: none;
}

.primary-navigation .sub-menu:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #4c4d4f;
  border-width: 10px;
  left: 50px;
}
.primary-navigation .sub-menu:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #ffffff;
  border-width: 11px;
  left: 49px;
}

.primary-navigation .sub-menu ul {
  padding: 10px 15px;
  position: relative;
  z-index: 5000;
}

.primary-navigation .sub-menu li {
  margin: 0;
  float: none;
}

.primary-navigation .sub-menu a {
  width: auto;
  background: none;
  text-align: left;
  white-space: nowrap;
}

div[role="main"] {
  margin: 0 0 12px 0;
  padding: 0 0 22px 0;
  border-bottom: 1px solid #585555;
  overflow: hidden;
}

/* Footer */
.page-footer {
  margin: 0 0 30px 0;
  overflow: hidden;
}

.page-footer ul {
  margin: 0 0 12px 0;
  padding: 0;
  text-align: right;
  list-style: none;
}

.page-footer li {
  display: inline;
}

.page-footer a {
  font-size: 0.8em;
  color: #4c4d4f;
  text-decoration: none;
  text-transform: uppercase;
}

.page-footer .bsif-logo {
  margin-right: 40px;
  float: left;
}

.page-footer small {
  display: block;
  margin-top: 5px;
  width: 550px;
  float: left;
}

.page-footer .four-logo {
  float: right;
  text-transform: none;
}

.page-footer .four-logo img {
  margin-left: 5px;
}

/* 3. Modules
   ========================================================================== */

/* Articles */
.two-column header,
.full-width header {
  margin: 0 0 22px 0;
  padding: 0 0 22px 0;
  height: 270px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-bottom: 1px solid #585555;
}

.two-column header img,
.full-width header img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.two-column header h1,
.full-width header h1 {
  margin: 0;
  padding: 25px 80px 25px 30px;
  position: absolute;
  top: 88px;
  left: 0;
  z-index: 50;
  color: #fff;
  background: #006bb7;
}

.two-column > div {
  margin: 0 50px 0 30px;
  padding: 0;
  width: 530px;
  float: left;
}

.two-column h2,
.full-width h2 {
  margin: 0;
  color: #006bb7;
  font-weight: normal;
}

.two-column h3,
.full-width h3 {
  margin-bottom: 0;
  font-size: 0.8em;
  color: #006bb7;
  font-weight: normal;
}

.two-column a,
.full-width a {
  color: #4c4d4f;
}

.two-column a:hover,
.full-width a:hover {
  text-decoration: none;
}

.two-column ul,
.full-width ul {
  margin: 0;
  padding: 0 0 0 20px;
  font-size: 0.8em;
}

.two-column img,
.full-width img {
  border-radius: 8px;
}

.full-width > div,
.full-width.products > p {
  padding: 0 30px;
}

.full-width .two-column > div {
  width: 700px;
  margin-left: 0;
}

.full-width .two-column .images {
  margin: 0;
  width: 150px;
  float: right;
}

.full-width .two-column .images img {
  margin: 0 0 30px 0;
}

/* Product articles */
.products.full-width header,
.products.two-column header {
  margin: 0;
  padding: 0;
  height: 175px;
  width: 960px;
  position: relative;
  z-index: 1;
  border: none;
}

.products.full-width header div,
.products.two-column header div {
  padding: 20px 25px;
  width: 300px;
  height: 135px;
  background: #006bb7;
  position: relative;
  float: left;
  z-index: 1;
  color: #fff;
  border-radius: 8px 0 0 8px;
}

.products.full-width header h1,
.products.two-column header h1 {
  margin: 0;
  padding: 0;
  position: relative;
  top: 0;
  left: 0;
  background: none;
  font-size: 1.3em;
}

.products.full-width header p,
.products.two-column header p {
  margin: 0;
}

.products.full-width header img,
.products.two-column header img {
  position: relative;
  float: right;
  z-index: 1;
  border-radius: 0 8px 8px 0;
}

.products.full-width h2,
.products.two-column h2 {
  display: block;
  margin: 22px 0 30px 0;
  padding: 0 30px;
  clear: both;
  font-size: 1em;
  line-height: 50px;
  color: #fff;
  border-radius: 8px;
  background: #006bb7;
}

.products.full-width h3 {
  margin: 1em 0;
  font-size: 1.6em;
}

.products.two-column > div {
  padding: 0 30px 0 0;
  width: 215px;
}

.products.two-column > div ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.products.two-column > div a,
.products.full-width > div a {
  text-decoration: none;
  color: #4c4d4f;
}

.products.two-column > div a:hover,
.products.full-width > div a:hover {
  text-decoration: underline;
}

.products.two-column > div span {
  font-weight: bold;
}

.products.two-column > section {
  width: 635px;
  float: right;
}

.products.full-width > section ul,
.products.two-column > section ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Product table */
.product-table {
  margin: 0 0 30px 0;
  border: 1px solid #85e4ff;
  border-radius: 8px;
  overflow: hidden;
}

.product-table div {
  padding: 0 30px;
  color: #006bb7;
  overflow: hidden;
}

.product-table div div {
  margin: 0 20px 0 0;
  width: 353px;
  float: left;
}

.product-table div div p {
  font-size: 1em;
  color: #4c4d4f;
}

.product-table figure {
  padding: 0;
  width: 220px;
  float: right;
  text-align: right;
}

.product-table figure img {
  margin: 20px 0 0 0;
  max-width: 220px;
}

.product-table .table-wrapper {
  padding: 30px;
  background: #006bb7;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.product-table table {
  width: 100%;
  max-width: 900px;
  color: #fff;
}

.product-table table th,
.product-table table td {
  padding: 0 20px;
  vertical-align: top;
  font-size: 1em;
  text-align: left;
  border-left: 1px solid #fff;
}

.product-table table th:first-child,
.product-table table td:first-child {
  padding: 0 30px 0 0;
  border-left: none;
}

.product-table table th {
  color: #85e4ff;
}

.two-column .product-table div {
  padding: 0 20px 20px 20px;
}

.two-column .product-table div div {
  padding: 0;
}

.two-column .product-table h3 {
  margin-bottom: 0;
  font-size: 1.6em;
}

.two-column .product-table .lead {
  font-size: 1.6em;
  color: #4c4d4f;
}

.two-column .product-table ul {
  font-size: 1em;
  color: #4c4d4f;
}

.two-column .product-table .table-wrapper {
  padding: 20px;
}

.two-column .product-table table {
  max-width: 573px;
}

.two-column .product-table th,
.two-column .product-table td {
  white-space: nowrap;
  padding: 0 10px;
}

/* News article listings */

ul.news-listing {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;
}

ul.news-listing li {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #585555;
}

ul.news-listing li:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}

ul.news-listing h1 {
  display: block;
  clear: both;
  font-size: 1.6em;
  color: #006bb7;
  font-weight: normal;
}

ul.news-listing p {
  font-size: 1em;
}

/* vCard */
.vcard a {
  color: #4c4d4f;
}

/* CK Editor */

.cke_editable_themed table {
  width: 100%;
  max-width: 900px;
}

.cke_editable_themed table th,
.cke_editable_themed table td {
  padding: 0 20px;
  vertical-align: top;
  font-size: 1em;
  text-align: left;
  border: 1px solid #000;
}

.cke_editable_themed table th:first-child,
.cke_editable_themed table td:first-child {
  padding: 0 30px 0 0;
}
body.cke_editable_themed {
  word-wrap: break-word;
  max-width: 100%;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (min-width: 35em) {
}

@media print,
  (-o-min-device-pixel-ratio: 5/4),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 120dpi) {
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  text-indent: -9999px;
}

.ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
  * {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /*
     * Don't show links for images, or javascript/internal links
     */

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; /* h5bp.com/t */
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}
